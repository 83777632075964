import React from "react";
import PropTypes from "prop-types";
import { defineMessages, intlShape, injectIntl } from "react-intl";
import bindAll from "lodash.bindall";
import { connect } from "react-redux";

import { setProjectUnchanged } from "../reducers/project-changed";
import {
  LoadingStates,
  getIsCreatingNew,
  getIsFetchingWithId,
  getIsFetchingWithoutId,
  getIsLoading,
  getIsShowingProject,
  onFetchedProjectData,
  onLoadedProject,
  projectError,
  setProjectId,
  setProjectFile,
  setProjectTitle,
} from "../reducers/project-state";
import { activateTab, BLOCKS_TAB_INDEX } from "../reducers/editor-tab";

import log from "./log";
import storage from "./storage";

const messages = defineMessages({
  loadError: {
    id: "gui.projectLoader.loadError",
    defaultMessage:
      "This project unable to process right now. Please try after some time.",
    description:
      "An error that displays when a local project file fails to load.",
  },
});

/* Higher Order Component to provide behavior for loading projects by id. If
 * there's no id, the default project is loaded.
 * @param {React.Component} WrappedComponent component to receive projectData prop
 * @returns {React.Component} component with project loading behavior
 */
const ProjectFetcherHOC = function (WrappedComponent) {
  class ProjectFetcherComponent extends React.Component {
    constructor(props) {
      super(props);
      bindAll(this, ["fetchProject", "fetchProjectFile"]);
      storage.setProjectHost(props.projectHost);
      storage.setAssetHost(props.assetHost);
      storage.setTranslatorFunction(props.intl.formatMessage);
      // props.projectId might be unset, in which case we use our default;
      // or it may be set by an even higher HOC, and passed to us.
      // Either way, we now know what the initial projectId should be, so
      // set it in the redux store.
      if (
        props.projectId !== "" &&
        props.projectId !== null &&
        typeof props.projectId !== "undefined"
      ) {
        this.props.setProjectId(props.projectId.toString());
      }

      if (
        props.projectFile !== "" &&
        props.projectFile !== null &&
        typeof props.projectFile !== "undefined"
      ) {
        this.props.setProjectFile(props.projectFile.toString());
      }
    }
    componentDidUpdate(prevProps) {
      console.log(this.props.loadingState);
      if (prevProps.projectHost !== this.props.projectHost) {
        storage.setProjectHost(this.props.projectHost);
      }
      if (prevProps.assetHost !== this.props.assetHost) {
        storage.setAssetHost(this.props.assetHost);
      }
      if (this.props.isFetchingWithId && !prevProps.isFetchingWithId) {
        if (this.props.reduxProjectId) {
          this.fetchProject(this.props.reduxProjectId, this.props.loadingState);
        }
      }
      if (this.props.isFetchingWithoutId && !prevProps.isFetchingWithoutId) {
        if (this.props.reduxProjectFile) {
          this.fetchProjectFile(
            this.props.reduxProjectFile,
            this.props.loadingState
          );
        }
      }
      if (this.props.isShowingProject && !prevProps.isShowingProject) {
        this.props.onProjectUnchanged();
      }
      if (
        this.props.isShowingProject &&
        (prevProps.isLoadingProject || prevProps.isCreatingNew)
      ) {
        this.props.onActivateTab(BLOCKS_TAB_INDEX);
      }
    }
    fetchProjectFile(projectFile, loadingState) {
      const that = this;
      const req = new XMLHttpRequest();
      req.open(
        "GET",
        "https://blocks.schoolforai.com/static/sb3/" +
          encodeURI(atob(projectFile)) +
          ".sb3",
        true
      );
      req.responseType = "blob";
      req.onload = function () {
        // const blob = new Blob([req.response], {
        //   type: "application/x.scratch.sb3",
        // });

        const fileReader = new FileReader();
        fileReader.onload = () => {
          const filename = projectFile;
          console.log(fileReader.result);
          that.props.vm
            .loadProject(fileReader.result)
            .then(() => {
              if (filename) {
                const uploadedProjectTitle = filename;
                //that.props.onSetProjectTitle(uploadedProjectTitle);
              }
              //loadingSuccess = true;
            })
            .catch((error) => {
              log.warn(error);
              alert(that.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
            })
            .then(() => {
              console.log(loadingState);
              that.props.onLoadingFinished(loadingState, true);
              //that.props.onFetchedProjectData({}, loadingState);
            });
        };
        fileReader.readAsArrayBuffer(req.response);
      };
      req.send();

      console.log(projectFile);
    }
    fetchProject(projectId, loadingState) {
      return storage
        .load(storage.AssetType.Project, projectId, storage.DataFormat.JSON)
        .then((projectAsset) => {
          if (projectAsset) {
            this.props.onFetchedProjectData(projectAsset.data, loadingState);
          } else {
            // Treat failure to load as an error
            // Throw to be caught by catch later on
            throw new Error("Could not find project");
          }
        })
        .catch((err) => {
          this.props.onError(err);
          log.error(err);
        });
    }
    render() {
      const {
        /* eslint-disable no-unused-vars */
        assetHost,
        intl,
        isLoadingProject: isLoadingProjectProp,
        loadingState,
        onActivateTab,
        onError: onErrorProp,
        onFetchedProjectData: onFetchedProjectDataProp,
        onLoadingFinished,
        onProjectUnchanged,
        onSetProjectTitle,
        projectHost,
        projectId,
        projectFile,
        reduxProjectId,
        reduxProjectFile,
        setProjectId: setProjectIdProp,
        setProjectFile: setProjectFileProp,
        /* eslint-enable no-unused-vars */
        isFetchingWithId: isFetchingWithIdProp,
        isFetchingWithoutId: isFetchingWithoutIdProp,
        ...componentProps
      } = this.props;
      return (
        <WrappedComponent
          fetchingProject={isFetchingWithIdProp}
          fetchingProjectFile={isFetchingWithoutIdProp}
          {...componentProps}
        />
      );
    }
  }
  ProjectFetcherComponent.propTypes = {
    assetHost: PropTypes.string,
    canSave: PropTypes.bool,
    intl: intlShape.isRequired,
    isCreatingNew: PropTypes.bool,
    isFetchingWithId: PropTypes.bool,
    isFetchingWithoutId: PropTypes.bool,
    isLoadingProject: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    loadingState: PropTypes.oneOf(LoadingStates),
    onActivateTab: PropTypes.func,
    onError: PropTypes.func,
    onFetchedProjectData: PropTypes.func,
    onLoadingFinished: PropTypes.func,
    onProjectUnchanged: PropTypes.func,
    onSetProjectTitle: PropTypes.func,
    projectHost: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    projectFile: PropTypes.string,
    reduxProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reduxProjectFile: PropTypes.string,
    setProjectId: PropTypes.func,
    setProjectFile: PropTypes.func,
    vm: PropTypes.shape({
      loadProject: PropTypes.func,
    }),
  };
  ProjectFetcherComponent.defaultProps = {
    assetHost: "https://assets.scratch.mit.edu",
    projectHost: "https://projects.scratch.mit.edu",
  };

  const mapStateToProps = (state) => ({
    isCreatingNew: getIsCreatingNew(state.scratchGui.projectState.loadingState),
    isFetchingWithId: getIsFetchingWithId(
      state.scratchGui.projectState.loadingState
    ),
    isFetchingWithoutId: getIsFetchingWithoutId(
      state.scratchGui.projectState.loadingState
    ),
    isLoadingProject: getIsLoading(state.scratchGui.projectState.loadingState),
    isShowingProject: getIsShowingProject(
      state.scratchGui.projectState.loadingState
    ),
    loadingState: state.scratchGui.projectState.loadingState,
    reduxProjectId: state.scratchGui.projectState.projectId,
    reduxProjectFile: state.scratchGui.projectState.projectFile,
    vm: state.scratchGui.vm,
  });
  const mapDispatchToProps = (dispatch, ownProps) => ({
    onActivateTab: (tab) => dispatch(activateTab(tab)),
    onError: (error) => dispatch(projectError(error)),
    onFetchedProjectData: (projectData, loadingState) =>
      dispatch(onFetchedProjectData(projectData, loadingState)),
    onLoadingFinished: (loadingState, success) => {
      dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
    },
    setProjectId: (projectId) => dispatch(setProjectId(projectId)),
    setProjectFile: (projectFile) => dispatch(setProjectFile(projectFile)),
    onProjectUnchanged: () => dispatch(setProjectUnchanged()),
    onSetProjectTitle: (title) => dispatch(setProjectTitle(title)),
  });
  // Allow incoming props to override redux-provided props. Used to mock in tests.
  const mergeProps = (stateProps, dispatchProps, ownProps) =>
    Object.assign({}, stateProps, dispatchProps, ownProps);
  return injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      mergeProps
    )(ProjectFetcherComponent)
  );
};

export { ProjectFetcherHOC as default };
